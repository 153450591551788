import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog33/image1.jpg"
import image2 from "../../images/blog33/image2.jpg"
import image3 from "../../images/blog33/image3.jpg"







export default function WhatCausesLowBackPain(){
    return(
        <BlogPostOld
            src={image3}
            title={"What Causes Low Back Pain?"}
            summary={"I couldn’t tell you how many times I have gotten this question throughout the span of my professional career. It is literally countless. This also tells me how far we are from addressing one of the most costly ailments in our society. The fact that I get this question so often, leads me to believe how misinformed the society is when it comes to lower back pain."}
            date={"Mar. 22, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <div>
                <p>I couldn’t tell you how many times I have gotten this question throughout the span of my professional career. It is literally countless. This also tells me how far we are from addressing one of the most costly ailments in our society.&nbsp;</p>
                <p>The fact that I get this question so often, leads me to believe how misinformed the society is when it comes to lower back pain.People that are in pain value advice from physicians, family members and friends and <a href="https://www.jospt.org/doi/full/10.2519/jospt.2018.0610" target="_blank" rel="noopener">we know that a series or even a single statement can “heavily influence recovery expectations”</a>.&nbsp;</p>
                <p>I think the healthcare industry as a whole plays a large role in planting this misconception into our community because the traditional biomedical model is geared towards “finding a cause and fixing the cause”.&nbsp;</p>
                <h2>Pain is not so simple</h2>
                <p>This simplistic model has been planted into the community and the roots continue to grow stronger since we are sold that we should jump to cure any sign of pain. Marketers continue to make companies insanely profitable while our country is suffering low back pain and disability at a higher rate than ever. They are doing this by scaring us to believe that pain is the boogie man and we must act (buy) now for a “simple fix”. Well I call BS.</p>
                <p>Pain is VERY complex. Lower back pain has been extensively researched and it has been determined that 80-90 percent of back pain conditions cannot be attributed to a single cause. These are the red flags we mentioned above as well as physician diagnosed Sciatica. Below is a graphic that illustrates how complex pain is by visually representing the possible factors that can be affecting a painful experience.&nbsp;</p>
                <img src={image} alt={"blog"}/>
                <p>This is a very important concept to drill home because if we understand these things can affect the pain we experience, we can do something about it. If you’re a high stressed individual, calming and meditative strategies with long relaxing breaks could be helpful. If you drink everyday and that comes along with junk food, this may be a low hanging fruit to decrease systemic inflammation. If you are constantly worried and fearful about your back pain, it may be helpful to envision yourself doing very physically challenging things with your body feeling great.&nbsp;</p>
                <h2>How to Manage Low Back Pain</h2>
                <p>We like to think of pain as a multifactorial mixed drink and pain is felt most when that drink is overflowing. We learned this analogy from Greg Lehamn, a researcher, chiropractor and physical therapist in Canada. Below is a great graphic depicting factors that can influence a painful experience.&nbsp;</p>
                <img src={image2} alt={"blog"}/>
                <p>There are two things we can do to help with pain management and ideally kickstart confidence to remain as physically active as possible throughout your lifespan. One, we can remove some liquid from the cup by addressing habits, fear, anxiety, activity levels (doing too much too soon or too little due to fear avoidance). These are all great pain management options that can provide fairly fast results depending on the case.&nbsp;</p>
                <p>The second option is to give you a bigger cup by becoming more resilient (physically, emotionally and mentally). Oftentimes, resiliency takes time so this is more of a long term process. We always aim to do both but understanding the latter takes consistency over a long period of time is important.&nbsp;</p>
                <p>We guide people by managing all of these factors and help change habits and feel the power of confidence in their body. This is done no matter what the “diagnosis” is (outside of the medical red flags).&nbsp;</p>
                <p>The specific diagnosis becomes less important in a traditional sense because it doesn’t change what we do. However, it can be useful to know a little bit about the “condition” that google, your doctor or your friends told you that you have.&nbsp;</p>
                <p>Remember that knowledge and advice is powerful, for the good or bad. Advice to avoid things without a plan to get back to physical activity can lead to an increase of fear avoidance. Rudyard Kipling, an English novelist who created the stories of Jungle Book said it best “Words are, of course, the most powerful drug used by mankind”.&nbsp;</p>
                <h2>Physical Activity is GOOD for Low Back Pain</h2>
                <p>I talked a little about this in a previous blog <a href="https://kirehab.com/blog/why-your-back-pain-should-not-stop-you-from-being-physically-active" target="_blank" rel="noopener">“Why Your Back Pain Should NOT Stop You From Being Physically Active”</a> but I will riff on it some more here because this stuff is important and we need to STOP telling people to avoid things without helping them stay active in the meantime. It makes ZERO sense.&nbsp;</p>
                <p>From the works of Michael Rey (a fellow no crazy Chiro - much love) and company, we know that those who do not adhere to physical activity guidelines are 2x more likely to experience chronic pain somewhere in the body. So getting back to physical activity should be the number one goal of any plan. How one does it depends on the individual and is shaped by their goals, current activity levels and expectations.&nbsp;</p>
                <p><a href="https://www.thespinejournalonline.com/article/S1529-9430(23)00110-9/fulltext#:~:text=No%20significant%20associations%20were%20identified,having%20a%20severe%20LBP%20trajectory." target="_blank" rel="noopener">This study</a> may be even more interesting. Those who are experiencing lower back pain and are partaking in moderate to vigorous activities are least likely to have severe lower back pain in the future. On the other hand, those who performed light physical activity or sedentary behavior had no association with lower back pain in the future. So the MORE intense workouts were associated with LESS severe pain in the future. Is your mind blown yet?</p>
                <h2>Summary of What Causes Lower Back Pain</h2>
                <p>So, what is the cause of your back pain? Most likely couldn’t give a simple answer to that. But we do know what needs to be done to help manage symptoms but more importantly encourage lifelong healthy habits: Find a way to stay physically active.</p>
                <p>This is our job. This is why we make sure to create relationships with everyone we help. At the end of the day, everyone deserves the opportunity to feel confident in their body and that is what we are after.</p>
            </div>

        </BlogPostOld>

    )
}